<template>
  <div class="punchtheclock">
    <!-- <div class="punchtheclockHead">
            活动打卡
    </div>-->
    <!-- 没有登入的情况 -->
    <!-- <button @click="isToken">看是否被</button> -->
    <div v-if="!noLogin" class="toLogingBox">
      <div class="LoginImgbox">
        <img src="../../../assets/images/wm_img_no_login.png" alt />
      </div>
      <p v-if="!qiyeData">你还没有登录哦，快去登录吧</p>
      <button @click="tosivaliztionLogin" v-if="!qiyeData">登录</button>
      <p v-if="qiyeData">企业不能操作当前模块，请切换身份！</p>
    </div>
    <!-- 已经登入的情况 -->
    <div v-else class="activeContent">
      <mescroll-vue ref="mescroll" class="gundong" :up="mescrollUp" @init="mescrollInit">
        <div id="activeKongContent"></div>
        <div v-for="(item,index) in activeList" :key="index" class="activeLIstBox">
          <div class="activeInformation">
            <!-- <div>
                            <img class="info_img" src="../../../assets/applicationBackground.png" alt="">
            </div>-->
            <div class="info_detail" @click="toActiveDetail(item)">
              <p class="info_title">{{item.name}}</p>
              <p class="info_other">联系人：{{item.leaderName}}</p>
              <p class="info_other">联系电话：{{item.leaderPhone}}</p>
              <p class="info_other">活动地点：{{item.activityLocation}}</p>
              <p class="info_other">开始时间：{{item.activityBeginTime}}</p>
              <p class="info_other">结束时间：{{item.activityEndTime}}</p>
            </div>
          </div>
          <div class="info_line"></div>
          <div class="info_bottom">
            <p></p>
            <!-- <p>活动时间：{{item.updateTime | dateFormatOne }}</p> -->
            <button v-if="item.status===5">已结束</button>
            <button v-else class="btn_bg">进行中</button>
          </div>
        </div>
      </mescroll-vue>
    </div>
  </div>
</template>

<script>
import MescrollVue from 'mescroll.js/mescroll.vue'
import { DropdownMenu, DropdownItem } from 'vant'
import Punchclockapi from '@/api/punchclock'

export default {
  components: {
    MescrollVue
  },
  data() {
    return {
      activeList: [],
      mescroll: null, // mescroll实例对象
      mescrollUp: {
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 10 // 每页数据的数量
        },
        htmlNodata: '<p class="upwarp-nodata">-- 到底啦 --</p>',
        noMoreSize: 5,
        empty: {
          // 列表第一页无任何数据时,显示的空提示布局; 需配置warpId才生效;
          warpId: 'activeKongContent', // 父布局的id;
          icon: require('../../../assets/mescroll/mescroll-empty.png'), // 图标,支持网络图
          tip: '暂无相关数据~', // 提示
        },
        auto: true,
        lazyLoad: {
          use: true // 是否开启懒加载,默认false
        }
      },
      noLogin: false,
      qiyeData: false
    }
  },
  created() {
    let token = window.localStorage.userinfo ? JSON.parse(window.localStorage.userinfo).token : false;
    this.qiyeData = false;
    if (token) { // 登入
      this.noLogin = true;
    } else { // 没有登入
      this.noLogin = false;
      if (window.localStorage.enterpriseInfo && window.localStorage.enterpriseInfo != 'undefined') {
        this.qiyeData = true;
      }
    }
  },
  methods: {
    mescrollInit(mescroll) {
      this.mescroll = mescroll
    },
    upCallback(page, mescroll) {
      this.page = page;
      this.mescroll = mescroll;
      this.indexListJavaPage(page, mescroll);
    },
    // 打卡列表
    indexListJavaPage(page, mescroll) {
      const params = { page: page.num, size: page.size, memberId: JSON.parse(window.localStorage.userinfo).id }
      Punchclockapi.getPunchclockList(params).then((res) => {
        console.log(res.data);
        if (res.success) {
          if (page.num === 1) {
            this.activeList = [];
          }
          if (!res.data.items) {
            res.data.items = [];
          }
          // 判断当前活动是否结束
          this.activeList = this.activeList.concat(res.data.items);
          this.$nextTick(() => {
            mescroll.endSuccess(res.data.items.length);
          })
        } else {
          // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
          mescroll.endErr()
        }
      }).catch((err) => {
        // alert(err);
        mescroll.endErr();
      })
    },
    // 进入活动详情
    toActiveDetail(item) {
      console.log(item);
      var link = window.location.href.split("#/")[0] + "#/activeDetail?id=" + item.id; //链接
      window.location.href = link;
    },
    // 跳登入
    tosivaliztionLogin() {
      this.$router.push('/login');
    }
  }
}
</script>

<style>
.punchtheclock .mescroll-empty {
  margin-top: 20vh;
}
.van-radio__label {
  margin: 0.05rem 0 0.05rem 0;
}
</style>

<style scoped lang="less">
.punchtheclock {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fafafa;
  .punchtheclockHead {
    height: 64px;
    width: 100%;
    box-sizing: border-box;
    padding-top: 24px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    background: #1377e2;
    font-size: 16px;
  }
  .activeContent {
    width: 100%;
    height: 100%;
    .drop_menu_wrapper {
      display: flex;
      justify-content: center;
      width: 100vw;
      background: #f9f2f2;
      .drop_menu {
        width: 34vw;
        border-radius: 5px;
      }
    }
    .activeLIstBox {
      width: 94%;
      height: auto;
      border-radius: 5px;
      background: #fff;
      margin: 0.2rem auto;
      padding: 0.2rem;
      box-sizing: border-box;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      position: relative;
      overflow: hidden;
      .activeInformation {
        display: flex;
        .info_img {
          width: 33vw;
          height: 41vw;
          border-radius: 5px;
          margin-right: 5px;
        }
        .info_detail {
          width: 100%;
          margin-top: 0.1rem;
          font-size: 0.28rem;
          // font-weight: bold;
          // height: 30px;
          // line-height: 30px;
          .info_title {
            font-size: 0.32rem;
            font-weight: bold;
          }
          .info_other {
            color: #a6a6a6;
          }
          p {
            margin-top: 4px;
          }
        }
      }
      .info_line {
        margin: 0.2rem 0;
        border-bottom: #dcdcdc 1px solid;
      }
      .info_bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          color: #a6a6a6;
        }
        button {
          background: none;
          color: #db2d33;
          border-radius: 10px;
          width: 16vw;
          border-color: #db2d33;
          border-style: solid;
          border-width: 1px;
        }
        .btn_bg {
          color: #00a0e9;
          border-color: #00a0e9;
        }
      }
      .statusContent {
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 200px;
        height: 200px;
        color: #fff;
        background: rgba(240, 133, 133, 0.5);
        text-align: center;
        line-height: 35px;
        font-size: 16px;
        transform: rotate(-45deg) translate(0px, 185px);
      }
      .statusContentBack {
        background: rgba(107, 107, 107, 0.1);
      }
    }
  }
  .toLogingBox {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    .LoginImgbox {
      width: 190px;
      height: auto;
      img {
        width: 100%;
        height: auto;
      }
    }
    p {
      height: 40px;
      line-height: 40px;
      color: #999;
      font-size: 14px;
    }
    button {
      border: none;
      outline: none;
      background: #1377e2;
      color: #fff;
      padding: 8px 50px;
      border-radius: 3px;
    }
  }
}
</style>