<template>
  <div class="allActive">
    <mescroll-vue
      class="voluntaryRecruitment"
      ref="mescroll"
      :down="mescrollDown"
      :up="mescrollUp"
      @init="mescrollInit"
    >
      <!-- 查询条件 -->
      <div class="queryCriteria">
        <!-- 活动状态 -->
        <div class="activityStatus" @click="statusshow=true">
          <div v-if="xianshiBlock.activityStatus">{{xianshiBlock.activityStatus}}</div>
          <div v-else class="tips">请选择活动状态</div>
          <van-icon name="arrow" />
        </div>
        <!-- 活动类型 -->
        <div class="activityType" @click="TypeShow=true">
          <div v-if="params.serviceTypeId">{{xianshiBlock.serviceTypeId}}</div>
          <div v-else class="tips">请选择活动类型</div>
          <van-icon name="arrow" />
        </div>
        <!-- 活动区域 -->
        <div class="activityRegion" @click="chooseRegin">
          <div v-if="params.selectRegionId">{{xianshiBlock.selectRegionId}}</div>
          <div v-else class="tips">请选择活动区域</div>
          <van-icon name="arrow" />
        </div>
      </div>
      <div
        class="volunteerBlock"
        v-for="(items, index) in dataList"
        :key="index"
        @click="goDetil(items)"
      >
        <div class="activeTitle">
          <span>{{items.name}}</span>
          <span class="startBg">{{items.status|statusContent}}</span>
        </div>
        <p class="activeContent">{{items.introduction}}</p>
        <ul class="activeDetail">
          <li>{{items.activityLocation}}</li>
          <li>活动开始时间：{{items.activityBeginTime}}</li>
          <li>
            <span>招募人数：{{items.recruitPeople}}</span>
          </li>
        </ul>
      </div>
      <div id="emptyAllactive"></div>
    </mescroll-vue>
    <!-- 活动区域弹框 -->
    <van-popup v-model="regrionIdShow" position="bottom" :style="{ height: '100%' }">
      <div class="popHead">
        <div @click="clearRegin">清空</div>
        <div @click="regrionIdShow=false">关闭</div>
      </div>
      <tree :list="list" :treetype="1"></tree>
    </van-popup>
    <!-- 活动类型 -->
    <van-popup v-model="TypeShow" position="bottom" :style="{ height: '100%' }">
      <div class="popHead">
        <div @click="clearType">清空</div>
        <div @click="TypeShow=false">关闭</div>
      </div>
      <tree :list="serveList" :treetype="2"></tree>
    </van-popup>
    <!-- 活动状态 -->
    <van-popup
      v-model="statusshow"
      closeable
      :style="{ height: '47vw',width:'47vw',paddingTop:'10vw',boxSizing:'border-box'}"
    >
      <van-radio-group v-model="params.activityStatus" @change="statusshowChane">
        <div class="activeStatusBox">
          <van-radio name>全部状态</van-radio>
          <van-radio name="5">活动完成</van-radio>
          <van-radio name="6">正在招募</van-radio>
          <van-radio name="7">正在进行</van-radio>
        </div>
      </van-radio-group>
    </van-popup>
  </div>
</template>

<script>
import tree from "@/components/massorder/tree";
// 引入mescroll的vue组件
import MescrollVue from 'mescroll.js/mescroll.vue'
import Recruitapi from '@/api/recruit'
import peopleOrderApi from "@/api/peopleOrder";
import Bus from "@/bus";
import { Toast } from "vant";

export default {
  data() {
    return {
      mescroll: null, // mescroll实例对象
      mescrollDown: {}, //下拉刷新的配置. (如果下拉刷新和上拉加载处理的逻辑是一样的,则mescrollDown可不用写了)
      mescrollUp: { // 上拉加载的配置.
        callback: this.upCallback, // 上拉回调,此处简写; 相当于 callback: function(page, mescroll) { }
        //以下是一些常用的配置,当然不写也可以的.
        page: {
          num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
          size: 10 //每页数据条数,默认10
        },
        empty: {
          //列表第一页无任何数据时,显示的空提示布局; 需配置warpId才显示
          warpId: "emptyAllactive", //父布局的id (1.3.5版本支持传入dom元素)
          icon: require('../../../assets/mescroll/mescroll-empty.png'), // 图标,支持网络图
          tip: "暂无数据" //提示
        }
      },
      params: {
        page: 1,
        rows: 10,
        activityStatus: "", // 活动状态
        selectRegionId: "", // 区域
        serviceTypeId: "" // 服务类型
      },
      xianshiBlock: {
        activityStatus: "", // 活动状态
        selectRegionId: "", // 区域
        serviceTypeId: "" // 服务类型
      },
      dataList: [],
      list: [], // 乡镇数据
      serveList: [],
      regrionIdShow: false, // 区域弹框
      TypeShow: false, // 活动区域弹框
      statusshow: false
    }
  },
  components: {
    MescrollVue,
    tree
  },
  filters: {
    statusContent(str) {
      if (str == 0) {
        return '编辑'
      } else if (str == 1) {
        return '提交审核'
      } else if (str == 2) {
        return '审核不通过'
      } else if (str == 3) {
        return '审核通过'
      } else if (str == 4) {
        return '活动指派'
      } else if (str == 5) {
        return '活动完成'
      } else if (str == 6) {
        return '正在招募'
      } else if (str == 7) {
        return '正在进行'
      }
    }
  },
  created() {
    this.getRegionData();
    this.getservertree(); // 获取服务类型
    Bus.$on("regionTree", (data) => {
      this.regrionIdShow = false; // 关闭弹框
      this.params.selectRegionId = data.keyId;
      this.xianshiBlock.selectRegionId = data.name;
      this.onSearch(); // 查数据
    });
    Bus.$on('serviceTree', data => {
      this.TypeShow = false; // 关闭弹框
      this.params.serviceTypeId = data.id;
      this.xianshiBlock.serviceTypeId = data.name;
      this.onSearch(); // 查数据
      // console.log(data);
    });
  },
  methods: {
    // 选中活动状态
    statusshowChane(value) {
      if (this.params.activityStatus == 5) {
        this.xianshiBlock.activityStatus = '完成活动';
      } else if (this.params.activityStatus == 6) {
        this.xianshiBlock.activityStatus = '正在招募';
      } else if (this.params.activityStatus == 7) {
        this.xianshiBlock.activityStatus = '正在进行';
      } else {
        this.xianshiBlock.activityStatus = '全部状态';
      }
      this.onSearch(); // 查数据
      this.statusshow = false; // 关闭弹框
    },
    // 清空区域
    clearRegin() {
      this.params.selectRegionId = "";
      this.xianshiBlock.selectRegionId = "";
      this.regrionIdShow = false;
      this.onSearch(); // 查数据
    },
    // 清空活动类型
    clearType() {
      this.TypeShow = false; // 关闭弹框
      this.params.serviceTypeId = '';
      this.xianshiBlock.serviceTypeId = '';
      this.onSearch(); // 查数据
    },
    // 获取乡镇部门
    getRegionData() {
      let params = {
        appName: this.appName
      }
      peopleOrderApi.regiontree(params).then(res => {
        if (res.success) {
          this.list = res.data
        } else {
          Toast(`${res.message}`);
        }
      })
    },
    //获取服务类型
    getservertree() {
      let params = {
        appName: this.appName
      }
      peopleOrderApi.servertree(params).then(res => {
        if (res.success) {
          console.log(res)
          this.serveList = res.data
        } else {
          Toast(`${res.message}`);
        }
      })
    },
    // 选择活动区域
    chooseRegin() {
      this.regrionIdShow = true;
    },
    // 跳转进入志愿者招募详情中
    goDetil(val) {
      if (this.isToken()) { // 没用登入调到登入
        var link = window.location.href.split("#/")[0] + "#/voluntaryRecruitmentDetail?id=" + val.id
        window.location.href = link
      }
    },
    mescrollInit(mescroll) { //滚动对象
      this.mescroll = mescroll;
    },
    upCallback(page, mescroll) {
      // console.log(page);
      this.params.page = page.num;
      this.params.rows = page.size;
      this.params.appName = this.appName;
      Recruitapi.allactiveDataSec(this.params).then((res) => {
        let arr = res.data.items;
        if (arr) {
          if (page.num === 1) {
            this.dataList = [];
          }
          this.dataList = this.dataList.concat(arr);
          this.$nextTick(() => {
            mescroll.endSuccess(arr.length);
          })
        } else {
          if (page.num === 1) {
            this.dataList = [];
          }
          this.$nextTick(() => {
            mescroll.endSuccess(0);
          })
        }
      }).catch((err) => {
        mescroll.endErr();
      })
    },
    onSearch() { // 活动的查询评分
      var page = {
        num: 1,
        size: 10,
        time: null
      }
      this.upCallback(page, this.mescroll);
    },
    // 点击清除按钮
    onClear() {
      this.onSearch();
    }
  }
}
</script>
<style>
.voluntaryRecruitment .mescroll-empty {
  margin-top: 100px;
}
.van-tabs--line .van-tabs__wrap {
  height: 10vw;
}
.van-tab {
  font-size: 0.27rem;
  padding: 0 4px;
  line-height: 10vw;
}
.van-tabs__line {
  width: 10vw;
  height: 3px;
}
</style>
<style lang="less" scoped>
.popHead {
  width: 100%;
  height: 11vw;
  display: flex;
  justify-content: space-between;
  align-content: center;
  border-bottom: 1px solid #ddd;
  > div {
    width: 50%;
    height: 100%;
    font-size: 0.27rem;
    display: flex;
    justify-content: center;
    align-items: center;
    &:last-child {
      border-left: 1px solid #ddd;
      box-sizing: border-box;
    }
  }
}
.activeStatusBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /deep/.van-radio {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    font-size: 0.27rem;
  }
}
.allActive {
  width: 100%;
  height: 100%;
}
.voluntaryRecruitment {
  width: 100%;
  height: 100%;
  padding: 0 10px;
  background-color: #efefef;
  overflow-y: auto;
  box-sizing: border-box;
  padding-top: 10px;
  .queryCriteria {
    width: 100%;
    // padding:0px 10px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    > div {
      width: 49%;
      height: 9vw;
      border: 1px solid #ddd;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.27rem;
      background: #fff;
      border-radius: 3px;
      padding: 0px 8px;
      box-sizing: border-box;
      &:last-child {
        width: 100%;
        margin-top: 5px;
      }
      .tips {
        color: #ddd;
      }
    }
  }
  .volunteerBlock {
    background-color: #fff;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    &:first-child {
      margin-top: 0px;
    }
    .activeTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // font-weight: 600;
      .startBg {
        background-color: #07c160;
      }
      .beingBg {
        background-color: #00a0e9;
      }
      .stopBg {
        background-color: #696969;
        font-size: 0.28rem;
      }
      span {
        &:nth-child(1) {
          width: 75%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 0.36rem;
        }
        &:nth-child(2) {
          width: 20%;
          text-align: center;
          padding: 1.5vw;
          font-size: 0.25rem;
          color: #fff;
          border-radius: 3px;
        }
      }
    }
    .activeContent {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      margin: 0.16rem 0;
      color: rgb(94, 94, 94);
      font-size: 0.32rem;
      line-height: 0.5rem;
    }
    .activeDetail {
      font-size: 3.5vw;
      color: rgb(94, 94, 94);
      padding: 10px;
      li {
        margin: 4px 0;
        line-height: 0.4rem;
        i {
          font-size: 3.5vw;
        }
      }
    }
  }
}
</style>
