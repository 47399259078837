<template>
  <div class="wholeActive">
    <span v-if="$route.query.backSign" class="first-back iconfont icon-fanhui" @click="goBackFiveOrder"></span>
    <van-tabs v-model="active" swipeable>
      <van-tab title="活动展示" name="1">
        <allActive/>
      </van-tab>
      <van-tab title="活动打卡" name="2">
        <punchtheclock/>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import allActive from "./allActive";
import punchtheclock from './punchtheclock'
export default {
  components:{
    allActive,
    punchtheclock
  },
  data(){
    return{
      active: 1
    }
  },
  methods: {
    goBackFiveOrder() {
      this.$router.go(-1)
    }
  }
}
</script>
<style scoped lang="less">
  .wholeActive{
    /deep/.van-tabs{
      width: 100%;
      height: 100%;
      .van-tabs__content{
        width: 100%;
        height: calc(100% - 44px);
        .van-tab__pane{
          width: 100%;
          height: 100%;
        }
      }
    }
    /deep/.van-tabs__line{
      background-color: #1377e2;
    }
  }
  .first-back {
    position: fixed;
    top: 13px;
    left: 10px;
    z-index: 999;
  }
</style>